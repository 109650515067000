import * as React from "react";
import { OrderItem } from "../models/order-item.model";
import { ProductVariation } from "../models/product-variation.model";
import { updateQuantity } from "../shared/update-state";
import { Order, OrderAddress } from "../models/order.model";
import productVariationService from "../service/product-variation.service";
import { v4 as uuidv4 } from "uuid";
import melhorEnvioService from "../service/melhor-envio.service";
import { DeliveryData } from "../models/melhor-envio/delivery-data.model";

interface CartContextData {
  isLoadingCart: boolean;
  order: Order;
  orderItems: OrderItem[];
  onAddOrderItem: (variation: ProductVariation) => void;
  onRemoveOrderItem: (variation?: ProductVariation) => void;
  isVariationOrdered: (variation: ProductVariation) => boolean;
  onAddOrderItemQuantity: (item: OrderItem) => void;
  onSubtractOrderItemQuantity: (item: OrderItem) => void;
  total: number;
  onChangeOrder: (order: Order) => void;
  stepsCompleted: StepsCompleted;
  orderAddress: OrderAddress;
  onChangeOrderAddress: (address?: OrderAddress) => void;
  orderFreight?: OrderFreight;
  onChangeOrderFreight: (orderFreight?: OrderFreight) => void;
  onClearCart: () => void;
  getTotalByPaymentMethod: (paymentMethod: string) => number;
  onCalculateFreight: (props?: CalculateFreightProps) => Promise<{
    data: DeliveryData[];
    item?: DeliveryData;
  }>;
  isFetchingFreight: boolean;
  shippingCompany?: DeliveryData;
  onChangeShippingCompany: (data?: DeliveryData) => void;
  shippingCompanies: DeliveryData[];
}

const CartContext = React.createContext<CartContextData>({} as CartContextData);

interface CartProviderProps {
  children: React.ReactNode;
}

export const CartProvider = ({ ...props }: CartProviderProps) => {
  const [isLoadingCart, setLoadingCart] = React.useState(true);
  const [sessionId, setSessionId] = React.useState("");
  const [order, setOrder] = React.useState<Order>({ ...new Order() });
  const [orderItems, setOrderItems] = React.useState<OrderItem[]>([]);
  const [orderAddress, setOrderAddress] = React.useState(new OrderAddress());
  const [orderFreight, setOrderFreight] = React.useState<OrderFreight>();
  const [isFetchingFreight, setFetchingFreight] = React.useState(false);
  const [shippingCompany, setShippingCompany] = React.useState<DeliveryData>();
  const [shippingCompanies, setShippingCompanies] = React.useState<
    DeliveryData[]
  >([]);

  const loadStorage = async () => {
    const sessionIdStorage = localStorage.getItem("session_id");
    const orderStorage = localStorage.getItem("order");
    const orderAddressStorage = localStorage.getItem("orderAddress");
    const orderItemsStorage = localStorage.getItem("orderItems");
    const orderFreightStorage = localStorage.getItem("orderFreight");

    if (!sessionIdStorage) {
      onClearCart();
      const id = uuidv4();
      setSessionId(id);
      localStorage.setItem("session_id", id);
      setLoadingCart(false);
      return;
    }

    if (sessionIdStorage) {
      setSessionId(sessionIdStorage);
    }

    if (orderStorage) {
      setOrder(JSON.parse(orderStorage));
    }

    if (orderAddressStorage) {
      setOrderAddress(JSON.parse(orderAddressStorage));
    }

    if (orderItemsStorage) {
      let orderItems = JSON.parse(orderItemsStorage) as OrderItem[];

      for (const orderItem of orderItems) {
        orderItem.productVariation = await productVariationService
          .getBySlug(orderItem?.productVariation?.slug)
          .then((res) => res?.data);
      }

      setOrderItems(orderItems);
    }

    if (orderFreightStorage) {
      setOrderFreight(JSON.parse(orderFreightStorage));
    }

    setLoadingCart(false);
  };

  const onChangeOrder = (order: Order) => setOrderStorage(order);

  const onChangeOrderAddress = (address?: OrderAddress) => {
    let newOrder = {
      ...order,
      country: "",
      state: "",
      city: "",
      zip_code: "",
      line_1: "",
      line_2: "",
      valueFreight: 0,
    } as Order;

    if (address) {
      newOrder = {
        ...newOrder,
        country: "BR",
        state: address?.state,
        city: address?.city,
        zip_code: address?.cep,
        line_1: `${address?.street}, ${address?.number} - ${address?.complement}`,
        line_2: address?.neighborhood,
      };
      setOrderAddress(address);
      localStorage.setItem("orderAddress", JSON.stringify(address));
      onCalculateFreight({ cep: address?.cep, state: address?.state });
    } else {
      localStorage.removeItem("orderAddress");
      setOrderAddress({ ...new OrderAddress() });
      onChangeShippingCompany(undefined);
    }

    setOrderStorage(newOrder);
  };

  const onAddOrderItem = (variation: ProductVariation) => {
    const orderItem = {
      ...new OrderItem(),
      idProductVariation: variation?.id,
      quantity: 1,
      valueTotal: variation?.product?.value,
      productVariation: variation,
    } as OrderItem;

    const newOrderItems = [...orderItems, orderItem];

    setOrderItemsStorage(newOrderItems);
    onCalculateFreight();
  };

  const onRemoveOrderItem = (variation?: ProductVariation) => {
    if (!variation) return;

    const newOrderItems = [
      ...orderItems?.filter(
        (item) => item?.productVariation?.id !== variation?.id
      ),
    ];

    if (!newOrderItems?.length) {
      localStorage.removeItem("orderItems");
      localStorage.removeItem("orderFreight");
      onChangeShippingCompany(undefined);
    }

    setOrderItemsStorage(newOrderItems);
    onCalculateFreight();
  };

  const onAddOrderItemQuantity = (item: OrderItem) => {
    const newOrderItems = [...orderItems];
    const index = orderItems?.indexOf(item);
    if (index !== -1) {
      newOrderItems[index].quantity += 1;
    }

    setOrderItemsStorage(newOrderItems);
    onCalculateFreight();
  };

  const onSubtractOrderItemQuantity = (item: OrderItem) => {
    const newOrderItems = [...orderItems];
    const index = orderItems?.indexOf(item);
    if (index !== -1) {
      newOrderItems[index].quantity = newOrderItems[index].quantity - 1;
    }

    setOrderItemsStorage(newOrderItems);
    onCalculateFreight();
  };

  const onChangeOrderFreight = (orderFreight?: OrderFreight) => {
    setOrderFreight(orderFreight);

    if (!orderFreight) {
      localStorage.removeItem("orderFreight");
    } else {
      localStorage.setItem("orderFreight", JSON.stringify(orderFreight));
    }
  };

  const onChangeShippingCompany = React.useCallback((data?: DeliveryData) => {
    setShippingCompany(data);
    onChangeOrderFreight(
      data
        ? {
            deliveryForecast: data?.custom_delivery_time?.toString(),
            value: Number(data?.price),
          }
        : undefined
    );
  }, []);

  const onCalculateFreight = async (props?: CalculateFreightProps) => {
    setShippingCompanies([]);

    let cep = "";
    let state = "";
    let data = {
      data: [],
      item: undefined,
    } as { data: DeliveryData[]; item?: DeliveryData };

    if (props) {
      cep = props?.cep;
      state = props?.state;
    } else {
      cep = order?.zip_code;
      state = order?.state;
    }

    if (!cep || !state) {
      onChangeShippingCompany(undefined);
      return data;
    }

    try {
      setFetchingFreight(true);

      const response = await melhorEnvioService.calculate(
        cep,
        orderItems,
        state
      );
      if (response?.data && response?.data?.length) {
        data.data = response?.data;

        data.data = data.data?.filter((item) => !item?.error);

        data.data = data?.data?.sort(
          (a, b) => Number(a?.price ?? 0) - Number(b?.price ?? 0)
        );

        if (data?.data?.length) {
          const item = data?.data?.reduce((acc, item) => {
            if (Number(acc?.price) > Number(item?.price)) {
              return item;
            } else {
              return acc;
            }
          }, data.data[0]);

          if (item) {
            onChangeShippingCompany(item);
            data.item = item;
          }
        }
      }
    } catch (error) {
      setFetchingFreight(false);
    } finally {
      setFetchingFreight(false);
    }

    setShippingCompanies(data.data);
    return data;
  };

  const onClearCart = () => {
    localStorage.removeItem("order");
    localStorage.removeItem("orderAddress");
    localStorage.removeItem("orderItems");
    localStorage.removeItem("orderFreight");
    setOrder(new Order());
    setOrderAddress(new OrderAddress());
    setOrderItems([]);
    setOrderFreight(undefined);
  };

  const setOrderItemsStorage = React.useCallback((items: OrderItem[]) => {
    setOrderItems(() => [...items]);
    localStorage.setItem("orderItems", JSON.stringify(items));
  }, []);

  const setOrderStorage = React.useCallback((order: Order) => {
    setOrder((x) => ({ ...order }));
    localStorage.setItem("order", JSON.stringify(order));
  }, []);

  const isVariationOrdered = (variation: ProductVariation) => {
    return orderItems?.some(
      (item) => item?.productVariation?.id === variation?.id
    );
  };

  const getTotalByPaymentMethod = React.useCallback(
    (paymentMethod: string) => {
      const value = orderItems?.reduce((a, b) => {
        const product = b?.productVariation?.product;

        let price = product?.valuePixBoleto ?? product?.promotionalValue ?? product?.value;
        
        if (paymentMethod === "credit_card") {
          price = product?.promotionalValue ?? price;
        }

        if (paymentMethod === "pix") {
          price = product?.valuePixBoleto ?? product?.promotionalValue ?? price;
        }

        if (paymentMethod === "boleto") {
          price = product?.valuePixBoleto ?? product?.promotionalValue ?? price;
        }

        return a + (price ?? 0) * b?.quantity;
      }, 0);

      return value;
    },
    [orderItems]
  );

  const total = React.useMemo(() => {
    return getTotalByPaymentMethod(order?.paymentMethod);
  }, [getTotalByPaymentMethod, order?.paymentMethod]);

  const stepsCompleted = React.useMemo(() => {
    return {
      one:
        !!order?.document && !!order?.email && !!order?.name && !!order?.phone,
      two:
        !!orderAddress?.cep &&
        !!orderAddress?.state &&
        !!orderAddress?.city &&
        !!orderAddress?.neighborhood &&
        !!orderAddress?.street &&
        !!orderAddress?.number,
      three: false,
    } as StepsCompleted;
  }, [order, orderAddress]);

  React.useEffect(() => {
    loadStorage();
  }, []);

  const context = {
    order,
    orderItems,
    total,
    isLoadingCart,
    stepsCompleted,
    orderAddress,
    orderFreight,
    onAddOrderItem,
    onRemoveOrderItem,
    isVariationOrdered,
    onAddOrderItemQuantity,
    onSubtractOrderItemQuantity,
    onChangeOrderAddress,
    onChangeOrder,
    onChangeOrderFreight,
    onClearCart,
    getTotalByPaymentMethod,
    onCalculateFreight,
    isFetchingFreight,
    shippingCompany,
    onChangeShippingCompany,
    shippingCompanies,
  };

  return (
    <CartContext.Provider value={context}>
      {isFetchingFreight && (
        <div className="full-loading">
          <i className="fa-solid fa-spinner fa-spin"></i>
        </div>
      )}
      {isLoadingCart ? <p>Loading...</p> : props.children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = React.useContext(CartContext);
  return context;
};

export default CartProvider;

type StepsCompleted = {
  one: boolean;
  two: boolean;
  three: boolean;
};

type OrderFreight = {
  deliveryForecast: string;
  value: number;
};

type CalculateFreightProps = {
  cep: string;
  state: string;
};
